import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout page="contact" fixedWidth>
    <SEO title="404: Not found" />
    <p>
      Unfortunately that link doesn't exist. Perhaps try going back to{" "}
      <Link to="/projects">the projects page</Link>.
    </p>
  </Layout>
)

export default NotFoundPage
