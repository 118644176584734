import React from "react"
import { Link } from "gatsby"

import Name from "./name"
import style from "./nav.module.scss"

import Strike from "../assets/strike-through.inline.svg"

const Nav = ({ page }) => {
  const pages = ["contact"]

  return (
    <nav className={`${style.main} main-nav`}>
      <Link to="/projects/" className={style.name}>
        <Name />
      </Link>
      <Link to="/" className={style.circle}>
        <span>Back to landing page</span>
      </Link>
      <ul className="handwriting">
        {pages.map(p => (
          <li key={p} className={page == p ? style.active : ""}>
            <Link to={`/${p}/`}>{p}</Link>
            <Strike />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Nav
