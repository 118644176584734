import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import layoutStyles from "./layout.module.scss"

const Layout = ({ children, page, fixedHeight, fixedWidth }) => {
  return (
    <div
      className={`${layoutStyles.wrapper} ${
        fixedHeight ? layoutStyles.fixedHeight : ""
      } ${fixedWidth ? layoutStyles.fixedWidth : ""}`}
    >
      <Nav page={page} />
      <main className={layoutStyles.main}>{children}</main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
